import { NavLinks } from './Header';

const Footer = () => {
	return (
		<footer class="footer-section">
			<div class="container">
				<NavLinks isFooter />
				<p class="copyright">
					Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with{' '}
					<i class="fa fa-heart-o" aria-hidden="true"></i> by{' '}
					<a href="https://colorlib.com" target="_blank">
						Colorlib
					</a>
				</p>
			</div>
		</footer>
	);
};

export default Footer;
