import { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Col, Input, Row } from 'reactstrap';
import AuthContext from './store/auth-context';
import './LevelsPage.css';

/**
 * Create React App:
 * 1) npx create-react-app gelman
 * 2) npm install reactstrap
 * 3) Include CSS in index.html
 * 4) npm start
 */
const LevelsPage = () => {
	const authContext = useContext(AuthContext);
	const [levelName, setLevelName] = useState('');
	const [creatorName, setCreatorName] = useState('');

	const [levels, setLevels] = useState([]);

	const fetchLevels = useCallback(async () => {
		const response = await fetch(`/api/level`, {
			method: 'POST',
			body: JSON.stringify({ name: levelName, creator: creatorName }),
			headers: {
				// This is required. NodeJS server won't know how to read it without it.
				'Content-Type': 'application/json',
			},
		});
		const level = await response.json();
		console.log('Retrieved Levels from Server', level);
		setLevels(level);
	}, [levelName, creatorName]);

	const handleSearchButton = () => {
		fetchLevels();
	};

	return (
		<>
			<section class="footer-top-section">
				<div class="container">
					<div class="footer-top-bg">
						<img src="img/footer-top-bg.png" alt="" />
					</div>

					<Row style={{ marginBottom: '30px' }}>
						<Col lg={5}>
							<div class="form-floating">
								<Input
									id="levelName"
									type="text"
									placeholder="Level Name"
									onChange={(e) => {
										setLevelName(e.target.value);
									}}
									value={levelName}
								/>
								<label for="levelName">Level Name</label>
							</div>
						</Col>

						<Col lg={5}>
							<div class="form-floating">
								<Input
									id="creatorName"
									type="text"
									placeholder="Creator Name"
									onChange={(e) => {
										console.log('CHANGES', e.target.value);
										setCreatorName(e.target.value);
									}}
									value={creatorName}
								/>
								<label for="creatorName">Creator Name</label>
							</div>
						</Col>
						<Col lg={2}>
							<Button onClick={handleSearchButton} className="site-btn">
								Search
							</Button>
						</Col>
					</Row>

					<Row>
						<Col lg={12}>
							<div class="footer-widget mb-5 mb-md-0">
								<h4 class="fw-title">Levels {!authContext.token && <span>(login to download)</span>}</h4>
								<div class="latest-blog">
									{levels?.length === 0 && (
										<div class="lb-item">
											<div class="lb-content">
												<div class="lb-date">No levels found.</div>
											</div>
										</div>
									)}
									{levels.map((level) => {
										return (
											<div class="lb-item" style={{ display: 'flex' }}>
												{authContext.token && (
													<div className="download-cell">
														<a href={`http://penguinore.net/gelman/levels/${level.name.toLowerCase()}.lvl`} class="site-btn">
															Download
														</a>
													</div>
												)}
												<div class="lb-content">
													<div class="lb-date">{level.name}</div>
													<span class="lb-author">By {level.creator}</span>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</section>
		</>
	);
};

export default LevelsPage;
