// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-menu ul li .work-in-progress {
	text-decoration: line-through;
	color: darkred;
}

.main-menu ul li .work-in-progress:hover {
	color: darkred;
}
`, "",{"version":3,"sources":["webpack://./src/Common/Header.css"],"names":[],"mappings":"AAAA;CACC,6BAA6B;CAC7B,cAAc;AACf;;AAEA;CACC,cAAc;AACf","sourcesContent":[".main-menu ul li .work-in-progress {\n\ttext-decoration: line-through;\n\tcolor: darkred;\n}\n\n.main-menu ul li .work-in-progress:hover {\n\tcolor: darkred;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
