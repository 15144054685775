import { useContext, useState } from 'react';
import LoginModal from './LoginModal';
import SignUpModal from './SignUpModal';
import AuthContext from '../store/auth-context';
import { useNavigate } from 'react-router-dom';

import './Header.css';

const Header = () => {
	const authContext = useContext(AuthContext);

	const [showLoginModal, setShowLoginModal] = useState(false);
	const showLoginModalHandler = () => setShowLoginModal(true);
	const hideLoginModalHandler = () => setShowLoginModal(false);

	const [showSignUpModal, setShowSignUpModal] = useState(false);
	const showSignUpModalHandler = () => setShowSignUpModal(true);
	const hideSignUpModalHandler = () => setShowSignUpModal(false);

	const nameDisplay = authContext.name ? `(${authContext.name})` : '';

	return (
		<header class="header-section">
			{showLoginModal && <LoginModal closeModalHandler={hideLoginModalHandler} showSignUpModal={showSignUpModalHandler} />}
			{showSignUpModal && <SignUpModal closeModalHandler={hideSignUpModalHandler} />}
			<div class="container">
				<a class="site-logo" href="index.html">
					<img src="img/logo.png" alt="" />
				</a>
				<div class="user-panel">
					{!authContext.token && (
						<a href="#" onClick={showLoginModalHandler} className="header-link">
							Login / Register
						</a>
					)}
					{authContext.token && (
						<a href="#" onClick={authContext.logoutHandler} className="header-link">
							<span>{nameDisplay} Logout</span>
						</a>
					)}
				</div>
				<div class="nav-switch">
					<i class="fa fa-bars"></i>
				</div>
				<nav class="main-menu">
					<NavLinks />
				</nav>
			</div>
		</header>
	);
};

export const NavLinks = ({ isFooter }) => {
	const navigate = useNavigate();

	return (
		<ul className={isFooter ? 'footer-menu' : ''}>
			<li>
				<a href="#" onClick={() => navigate('home')}>
					Home
				</a>
			</li>
			<li>
				<a className="work-in-progress" href="#" onClick={() => alert('Not available: Work in Progress')}>
					Players
				</a>
			</li>
			<li>
				<a href="#" onClick={() => navigate('levels')}>
					Levels
				</a>
			</li>
			<li>
				<a className="work-in-progress" href="#" onClick={() => alert('Not available: Work in Progress')}>
					Commands
				</a>
			</li>
			<li>
				<a className="work-in-progress" href="#" onClick={() => alert('Not available: Work in Progress')}>
					My Profile
				</a>
			</li>
			<li>
				<a className="work-in-progress" href="#" onClick={() => alert('Not available: Work in Progress')}>
					Contests
				</a>
			</li>
			<li>
				<a className="work-in-progress" href="#" onClick={() => alert('Not available: Work in Progress')}>
					Community
				</a>
			</li>
		</ul>
	);
};

export default Header;
