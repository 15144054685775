// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-card {
	/* background: url('../images/home_2.jpg'); */
	background-size: cover;
}
`, "",{"version":3,"sources":["webpack://./src/HomePage.css"],"names":[],"mappings":"AAAA;CACC,6CAA6C;CAC7C,sBAAsB;AACvB","sourcesContent":[".title-card {\n\t/* background: url('../images/home_2.jpg'); */\n\tbackground-size: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
