import './HomePage.css';

const HomePage = () => {
	return (
		<>
			<section class="page-info-section set-bg title-card" style={{ backgroundImage: 'url(/images/players_dark.jpg)' }}>
				<div class="pi-content">
					<div class="container">
						<div class="row">
							<div class="col-xl-5 col-lg-6 text-white">
								<h2>Welcome Gelman & Friends Alumni!</h2>
								<p>
									This site is a work in progress but I intend to build a museum for our popular server where you can download levels, view logs, players
									stats, and screenshots.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="recent-game-section spad set-bg">
				<div class="container">
					<div class="row">
						<div class="col-lg-4 col-md-6">
							<div class="recent-game-item">
								<div class="rgi-thumb set-bg" style={{ backgroundImage: 'url(/images/players_card.png)' }}></div>
								<div class="rgi-content">
									<h5>Players</h5>
									<p>View statistics of any players.</p>
								</div>
							</div>
						</div>
						<div class="col-lg-4 col-md-6">
							<div class="recent-game-item">
								<div class="rgi-thumb set-bg" style={{ backgroundImage: 'url(/images/levels_card.png)' }}></div>
								<div class="rgi-content">
									<h5>Levels</h5>
									<p>View information and download any level.</p>
								</div>
							</div>
						</div>
						<div class="col-lg-4 col-md-6">
							<div class="recent-game-item">
								<div class="rgi-thumb set-bg" style={{ backgroundImage: 'url(/images/commands_card.jpg)' }}></div>
								<div class="rgi-content">
									<h5>Commands</h5>
									<p>View the list of commands from MCLight.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default HomePage;
