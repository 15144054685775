// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 20;
	background-color: rgba(0, 0, 0, 0.75);
}

.modal-title {
	color: #214366;
	font-weight: bold;
	text-align: center;
	padding-bottom: 10px;
}

.modal-window {
	position: fixed;
	background-color: white;
	padding: 1rem;
	border-radius: 14px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
	z-index: 5000;
}

@media (min-width: 768px) {
	.modal-window {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
`, "",{"version":3,"sources":["webpack://./src/Common/Modal.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,MAAM;CACN,OAAO;CACP,WAAW;CACX,aAAa;CACb,WAAW;CACX,qCAAqC;AACtC;;AAEA;CACC,cAAc;CACd,iBAAiB;CACjB,kBAAkB;CAClB,oBAAoB;AACrB;;AAEA;CACC,eAAe;CACf,uBAAuB;CACvB,aAAa;CACb,mBAAmB;CACnB,yCAAyC;CACzC,aAAa;AACd;;AAEA;CACC;EACC,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;CACjC;AACD","sourcesContent":[".backdrop {\n\tposition: fixed;\n\ttop: 0;\n\tleft: 0;\n\twidth: 100%;\n\theight: 100vh;\n\tz-index: 20;\n\tbackground-color: rgba(0, 0, 0, 0.75);\n}\n\n.modal-title {\n\tcolor: #214366;\n\tfont-weight: bold;\n\ttext-align: center;\n\tpadding-bottom: 10px;\n}\n\n.modal-window {\n\tposition: fixed;\n\tbackground-color: white;\n\tpadding: 1rem;\n\tborder-radius: 14px;\n\tbox-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);\n\tz-index: 5000;\n}\n\n@media (min-width: 768px) {\n\t.modal-window {\n\t\tposition: absolute;\n\t\ttop: 50%;\n\t\tleft: 50%;\n\t\ttransform: translate(-50%, -50%);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
