import HomePage from './HomePage';
import NotFoundPage from './NotFoundPage';
import { Navigate, Route, Routes } from 'react-router-dom';
import Header from './Common/Header';
import LevelsPage from './LevelsPage';
import Footer from './Common/Footer';

function App() {
	return (
		<div>
			<Header />
			<div className="app">
				<Routes>
					<Route path="/" element={<Navigate to="home" />} />
					<Route path="levels" element={<LevelsPage />} />
					<Route path="home" element={<HomePage />} />
					<Route path="*" element={<NotFoundPage />} />
				</Routes>
			</div>
			<Footer />
		</div>
	);
}

export default App;
